












import { Component, Prop, Vue } from 'vue-property-decorator'
import FormBase from '@/shared/classes/form/form-base'
import HttpMethod from '@/shared/configs/http-method.config'
import Field from '@/shared/classes/form/field'
import { GlobalActions } from '@/shared/store/global/global.actions'
import PageHeader from '@/components/PageHeader.vue'
import Form from '@/components/form/Form.vue'
import SelectField from '@/shared/classes/form/fields/select/select-field'
import SelectItem from '@/shared/classes/form/fields/select/select-item'
import { RoleType, roleTypes } from '@/shared/configs/role.config'
import User from '@/shared/modules/user/user'
import { AuthGetters } from '@/shared/store/auth/auth.getters'
import { FieldType } from '@/shared/configs/field-type.config'

@Component({
  components: { PageHeader, Form }
})
export default class CreateUserDialog extends Vue {
  @Prop() meta!: any
  loading: boolean = false

  form: FormBase = new FormBase()
    .setEndpoint('users')
    .setFields([
      new Field()
        .setType(FieldType.email)
        .setKey('email')
        .setTitle('Email'),
      new Field()
        .setKey('name')
        .setTitle('Name'),
      new SelectField()
        .setKey('role')
        .setTitle('Role')
        .setItems(roleTypes.map((item: SelectItem) => item))
    ])
    .setInitialValues({
      role: RoleType.regular,
    })
    .setSubmit(false)

  closeDialog() {
    this.$store.dispatch(GlobalActions.closeDialog)
  }

  async submitForm() {
    this.loading = true
    const { form }: any = this.$refs

    const errors = await form.submit()

    this.loading = false

    if (errors) return

    this.closeDialog()
    this.meta.onSuccess && this.meta.onSuccess()
  }

  get user(): User {
    return this.$store.getters[AuthGetters.getUser]
  }
}
