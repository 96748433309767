












import { Component, Vue } from 'vue-property-decorator'
import DataTable from '@/components/data-table/DataTable.vue'
import DataTableBase from '@/shared/classes/data-table/data-table-base'
import DataTableHeader from '@/shared/classes/data-table/data-table-header'
import { GlobalActions } from '@/shared/store/global/global.actions'
import { SnackBarType } from '@/shared/helpers/snackbar.helper'
import CreateUserDialog from '@/components/users/CreateUserDialog.vue'
import EditAction from '@/components/data-table/default-actions/edit-action'
import User from '@/shared/modules/user/user'
import EditUserDialog from '@/components/users/EditUserDialog.vue'
import SelectItem from '@/shared/classes/form/fields/select/select-item'
import { roleTypes } from '@/shared/configs/role.config'

@Component({
  components: { DataTable }
})
export default class Users extends Vue {
  table = new DataTableBase()
    .setEndpoint(`users`)
    .setHeaders([
      new DataTableHeader()
        .setKey('name')
        .setTitle('Name'),
      new DataTableHeader()
        .setKey('email')
        .setTitle('Email'),
      new DataTableHeader()
        .setKey('role')
        .setTitle('Role')
        .setValueParser((value: string) => {
          const found: SelectItem | undefined = roleTypes.find((item: SelectItem) => item.value === value)

          if (! found) return value

          return found.title
        }),
    ])
    .setActions([
      new EditAction()
        .setAction((user: User) => {
          this.$store.dispatch(GlobalActions.showDialog, {
            maxWidth: 800,
            component: EditUserDialog,
            meta: {
              user,
              onSuccess: () => {
                this.$store.dispatch(GlobalActions.showSnackbar, {
                  type: SnackBarType.success,
                  message: 'User was successfully updated',
                })

                this.tableRef.refresh()
              }
            }
          })
        })
    ])

  openAddDialog() {
    this.$store.dispatch(GlobalActions.showDialog, {
      maxWidth: 800,
      component: CreateUserDialog,
      meta: {
        onSuccess: () => {
          this.$store.dispatch(GlobalActions.showSnackbar, {
            type: SnackBarType.success,
            message: 'User was successfully created',
          })

          this.tableRef.refresh()
        }
      }
    })
  }

  get tableRef(): any {
    return this.$refs.table
  }
}
