import SelectItem from '@/shared/classes/form/fields/select/select-item'

export enum RoleType {
  superAdmin = 'SUPER_ADMIN',
  regular = 'REGULAR',
}

export const roleTypes: SelectItem[] = [
  new SelectItem().setValue(RoleType.superAdmin).setTitle('Super Admin'),
  new SelectItem().setValue(RoleType.regular).setTitle('Regular'),
]
